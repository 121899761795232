import React, { useCallback, useContext } from "react";
import PortableText from "./portableText";
import { cn, toPlainText } from "../lib/helpers";
import { Byline } from "./card/byline";
import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import { Podcast, SectionWrapper } from "./sections";
import { ShareWidgets } from "./share-widgets";
import { pageDocPath } from "../../helpers";
import { CardGrid } from "./card/card-grid";
import { SiteContext } from "./global/site-context";
import { BackToZone } from "./back-to-zone";
import { Pills } from "./card/pills";
import { PublishDate } from "./card/publish-date";
import useMedia from "use-media";
import { Promo } from "./sections/promo";
import { MAX_SUGGESTED_POSTS } from "../lib/suggestions";
import { Button } from "./button";
import { ArrowLeft } from "./icon/arrow-left";
import { ImageBuilder } from "./global/image-builder";

import * as styles from "./post-page.module.css";

const HERO_IMAGE_WIDTH = 640;
const PAGE_CONTAINER = "narrow";

const PODCAST_RELATED_TAGS = [
  "da38ee48-4ccb-4632-aed4-fae6aeb77b8c",
  "e398c056-1d60-450c-9156-6f34a1808f89",
  "62c8d09b-665d-4ecb-bc7b-e6769e14963a",
];

export const BlogPostPage = ({
  title,
  authors,
  _rawBody,
  _rawExcerpt,
  mainImage,
  mainVideo,
  cardImage,
  hideMainMedia,
  slug,
  suggest,
  categories,
  readingTimeInMinutes,
  promo,
}) => {
  const site = useContext(SiteContext);

  const noMedia = hideMainMedia || (!mainImage && !mainVideo);
  const hasVideo = !hideMainMedia && mainVideo && mainVideo.url;
  const isLarge = useMedia({ minWidth: "1024px" });
  const podcastRelated = categories.some((c) =>
    PODCAST_RELATED_TAGS.includes(c._id)
  );

  const Share = useCallback(
    () => (
      <ShareWidgets
        title={title}
        excerpt={toPlainText(_rawExcerpt)}
        path={pageDocPath({ _type: "post", slug })}
        vertical={isLarge}
        size={24}
      />
    ),
    [title, _rawExcerpt, slug, site, isLarge]
  );

  return (
    <div className="pt-12 md:pt-20 max-w-screen-sm mx-auto">
      <div className="z-40 left-0 top-0 xl:pl-8 lg:fixed pt-8 lg:pt-20">
        <SectionWrapper padding="none" container="lg">
          <div className="mb-32 hidden lg:block"></div>
          {categories.find(
            (c) => c._id === "17e46daf-7cde-44a3-b8b3-7ffcf6c7b86b"
          ) && (
            <div className="mb-6">
              <Button
                text="Explore series"
                href="/series/mavericks/"
                icon={<ArrowLeft size="12" />}
                bgColor="bg-dark-10"
              />
            </div>
          )}

          <BackToZone to="/blog" text={site.token("backToBlog")} />

          <h3 className="text-sm mb-4">{readingTimeInMinutes} min read</h3>
          {isLarge && (
            <div className="mb-12">
              <Share />
            </div>
          )}
        </SectionWrapper>
      </div>

      <SectionWrapper container={PAGE_CONTAINER} padding="none">
        <div className="z-20 relative">
          {hasVideo && (
            <div>
              <YouTube
                videoId={getYouTubeId(mainVideo.url)}
                className="w-full rounded-2xl"
                containerClassName=""
              />
            </div>
          )}
          {!hideMainMedia &&
            !mainVideo &&
            (mainImage?.asset || cardImage?.assset) && (
              <div className="block relative rounded-2xl overflow-hidden">
                <ImageBuilder
                  image={cardImage || mainImage}
                  width={HERO_IMAGE_WIDTH}
                  height={
                    mainImage?.asset
                      ? Math.round(
                          HERO_IMAGE_WIDTH /
                            mainImage.asset?.metadata?.dimensions?.aspectRatio
                        )
                      : null
                  }
                  alt={cardImage ? title : mainImage.alt}
                />
              </div>
            )}
        </div>

        {noMedia && <h1 className="text-5xl mt-2">{title}</h1>}

        <div className="flex gap-1 items-center mt-4 mb-2">
          {categories && <Pills tags={categories} showSubTags={true} />}
          <div className="inline-block ml-1 text-sm text-gray-600">
            <PublishDate />
          </div>
        </div>

        {!noMedia && (
          <h1 className="text-5xl mt-2">
            {`${title}${hasVideo ? " [video]" : ""}`}
          </h1>
        )}

        {!isLarge && (
          <div className="mt-6">
            <Share />
          </div>
        )}
      </SectionWrapper>

      {/* {companies && companies.map((c) => <p>{c.title}</p>)} */}

      <SectionWrapper padding="" container={PAGE_CONTAINER}>
        {authors.length > 0 && (
          <div className="pt-3 pb-6 md:pt-0">
            <Byline />
          </div>
        )}
        <div className={cn("leading-relaxed", styles.mainContent)}>
          {_rawBody && <PortableText blocks={_rawBody} />}
        </div>
      </SectionWrapper>

      {promo && (
        <SectionWrapper container={PAGE_CONTAINER} padding="tight">
          <Promo {...promo} />
        </SectionWrapper>
      )}

      {site.isActive("suggestions") && suggest && (
        <SectionWrapper
          header={site.token("suggestions")}
          anchor="more"
          container="thin"
        >
          {podcastRelated && (
            <div className="mb-8">
              <Podcast />
            </div>
          )}

          <CardGrid
            nodes={suggest.slice(0, MAX_SUGGESTED_POSTS)}
            browseMoreHref="/blog"
            maxColumns={2}
            allowSummary={true}
            showSummary={true}
            panels={true}
            attribution={{ action: "suggestion", label: "post" }}
            hideType={true}
            hideTitles={true}
          />
        </SectionWrapper>
      )}
    </div>
  );
};
